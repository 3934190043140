<template>
  <div>
    <el-row style="margin-bottom: 5px; display: block">
      <div style="float:left;">
        <el-button type="primary" v-if="auth['pm:add'] && addflag==true" icon="el-icon-plus" @click="handle_add()">添加</el-button>
        <el-button type="success" icon="el-icon-refresh" @click="initPageData()">刷新</el-button>
      </div>
      <div style="float:right;">
        <el-input placeholder="客户名称" v-model="customerName" class="input-with-select" style="width:250px;"></el-input>
        <el-input placeholder="项目编号/名称" v-model="keyword" class="input-with-select" style="width:250px;"></el-input>&nbsp;
        <el-button type="primary" @click="handle_search()" icon="el-icon-search">搜索</el-button>
      </div>
    </el-row>

    <!--查询按钮-->
    <el-row style="margin-bottom: 5px;">
      <el-button plain v-if="projectType === null" type="primary">全部</el-button>
      <el-button plain v-if="projectType !== null" @click="loadByType(null)">全部</el-button>
      <el-button plain v-if="projectType === 0" type="primary">冷库</el-button>
      <el-button plain v-if="projectType !== 0" @click="loadByType(0)">冷库</el-button>
      <el-button plain v-if="projectType === 1" type="primary">冷藏车</el-button>
      <el-button plain v-if="projectType !== 1" @click="loadByType(1)">冷藏车</el-button>
      <el-button plain v-if="projectType === 2" type="primary">冷藏柜</el-button>
      <el-button plain v-if="projectType !== 2" @click="loadByType(2)">冷藏柜</el-button>
      <el-button plain v-if="projectType === 3" type="primary">保温箱</el-button>
      <el-button plain v-if="projectType !== 3" @click="loadByType(3)">保温箱</el-button>
      <el-button plain v-if="projectType === 4" type="primary">温湿度监控系统</el-button>
      <el-button plain v-if="projectType !== 4" @click="loadByType(4)">温湿度监控系统</el-button>
    </el-row>

    <el-table :data="page.list" v-loading="showLoading" element-loading-text="拼命加载中" border width="width:100%; height:50%">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="name" label="项目名称" width="300"></el-table-column>
      <el-table-column prop="reportno" label="项目编号" width="150"></el-table-column>
      <el-table-column prop="custname" label="客户名称" width="150"></el-table-column>
      <el-table-column prop="address" label="地址" width="300"></el-table-column>
<!--      <el-table-column prop="didName" label="所属部门" width="100"></el-table-column>-->
<!--      <el-table-column prop="uidName" label="负责人" width="100"></el-table-column>-->
      <el-table-column prop="activ" label="状态" width="100" :formatter="formatStatus"></el-table-column>
      <el-table-column prop="scope" label="操作" min-width="100">
        <template  #default="scope">
          <el-button type="text" v-if="auth['pm:update']" icon="el-icon-edit" @click="handler_completeproject(scope.row)">完善项目</el-button>
<!--          <el-button type="text" icon="el-icon-edit" @click="handle_editparam(scope.row)">完善参数</el-button>-->
          <el-button type="text" v-if="auth['pm:add'] && delflag==true" icon="el-icon-delete" class="red" @click="handle_del(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="devlogChange"
      :current-page="page.pageNum"
      :page-size="page.pageSize" layout="total, prev, pager, next, jumper"
      :total="page.total">
    </el-pagination>

  </div>
</template>

<script>
import PM from '../../api/base/api_pm';
import syscomm from '../../utils/syscomm';
import COMM from "../../api/base/api_comm";

export default {
  name: "projectlist",
  components: {
  },
  props: {
    customerInfo: {
      type: Object,
      default: {}
    },
    customerName: {
      type: String
    },
    keyword: {
      type: String
    },
    addflag: {
      type: Boolean,
      default: true
    },
    delflag: {
      type: Boolean,
      default: true
    },
    pmStatus: {
      type: Number
    }
  },
  data() {
    return {
      showLoading: false,
      page: {list: [], pageNum: 1, pageSize: 10, total: 0},
      editTitle: '更新项目信息',
      editShow: false,
      editProject: {
        custid: this.customerInfo.id
      },
      projectType: null,
      pmStatusList: undefined,
      auth: {}
    }
  },
  // 初始化加载
  mounted() {
      this.init();
  },
  // 方法定义
  methods: {
    init() {
      this.init_base();
      this.auth=this.$getAuth('/ProjectManage');
      this.initPageData();
    },

    // 加载初始数据支持
    init_base(){
      COMM.getObjType({type: 'pmstatus'}).then((res) => {
        this.pmStatusList = res;
      });
    },

    // 加载页面数据
    initPageData() {
      this.showLoading = true;
      let did = this.$store.state.cuttuser.deptId, nodeType = 0;

      PM.list({
        did: 0,
        nodeType: 0,//节点的类型
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        custid: this.customerInfo.id,
        customerName: this.customerName,
        type: this.projectType,
        key: this.keyword,
        status: this.pmStatus
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          var data = res.pageData;
          this.page.total = data.total;
          this.page.list = data.list;
        } else {
          this.$message.error(res.msg);
          this.page.list = [];
        }
      });
    },

    // 分页跳转
    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    },

    // 查询
    handle_search() {
      this.initPageData();
    },

    // 按类型加载项目
    loadByType(pmType) {
      this.projectType = pmType;
      this.initPageData();
    },

    // 编辑基础信息
    handle_edit(row) {
      let {...clonerow} = row;
      this.editProject = clonerow;
      this.editShow = true;
    },

    handle_add() {
      let pmid = null;
      // this.$router.push({path: '/projectEdit/' + this.customerInfo.id + '/' + pmid});
      this.$router.push({
        path: "../projectEdit",
        query: {
          projectId: pmid,
          customerId: this.customerInfo.id
        },
      });
    },

    handler_completeproject(row) {
      this.$router.push({
        path: "../projectEdit",
        query: {
          projectId: row.id,
          customerId: row.custid
        },
      });
    },

    // 删除
    handle_del(index, row) {
      this.$confirm('此操作将删除[' + row.name + '] 项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        PM.delete({id: row.id}).then((res) => {
          if (200 == res.code) {
            this.$message.success("操作成功！");
            this.initPageData();
          } else {
            this.$message.error(res.msg);
          }
        });
      }).catch(() => {
      });
    },

    // 完善参数
    handle_editparam(row) {
      let {...clonerow} = row;
      this.$router.push({ name: 'proExtInfo', params: { pmid: row.id } });
    },

    formatStatus(row, column) {
      let status = row?.status;
      for (const index in this.pmStatusList) {
        let pmStatus = this.pmStatusList[index];
        if (status == pmStatus['value']) {
          return pmStatus['name'];
        }
      }
      return '';
    },
  }
}
</script>

<style scoped>
.pageHeader {
  background-color: white;
  border-bottom: 1px solid #CCCCCC;
  font-size: 18px;
  padding: 5px 10px;
}

.red {
  color: #ff0000;
}
</style>